.keen-slider:not([data-keen-slider-disabled]) {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  align-content: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
  flex-direction: row-reverse;
}

.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v] {
  flex-wrap: wrap;
}
/*# sourceMappingURL=index.6ff1daea.css.map */
